import { CardAnimated, ViewCard } from "./Cards"
import { projectsFirstColumn } from "../data/projectData2"
import VideoModal from "./VideoModal"
import { useRef, useState } from "react"
import { motion } from "framer-motion"

export default function ProjectsInteractive() {
  const [open, setOpen] = useState(false)
  const [videoUrl, setVideoUrl] = useState("")
  const [projectName, setProjectName] = useState("")
  const [image, setImage] = useState("")
  const containerRef = useRef(null)
  const top = ["10%", "30%", "5%", "20%", "45%", "50%"]
  const left = ["7%", "10%", "72%", "75%", "75%", "45%"]
  const rotate = ["-3deg", "6deg", "-3deg", "8deg", "-16deg", "3deg"]
  const [selectedProject, setSelectedProject] = useState(projectsFirstColumn[0])

  const animationVariants = {
    initial: { opacity: 0, x: -50 },
    animate: { opacity: 1, x: 0 },
    exit: { opacity: 0, x: 50 },
  }

  return (
    <div className="bg-slate-100">
      <div className="relative grid min-h-[94vh] w-full place-content-center overflow-hidden">
        <div className="border border-slate-300 border-dashed rounded-lg p-4 m-10 overflow-hidden">
          {selectedProject ? (
            <motion.div
              key={selectedProject.name}
              initial="initial"
              animate="animate"
              exit="exit"
              variants={animationVariants}
              transition={{ duration: 0.5 }}
            >
              <ViewCard
                name={selectedProject.name}
                selectedProject={selectedProject}
                content={selectedProject.content}
                imageSrc={selectedProject.imageSrc}
                imageAlt={selectedProject.imageAlt}
                tags={selectedProject.tags}
                link={selectedProject.link}
                githubLink={selectedProject.githubLink}
                colour={selectedProject.colour}
                video={selectedProject.video}
                onClick={(event) => {
                  event.stopPropagation()
                  setVideoUrl(selectedProject.video)
                  setImage(selectedProject.imageSrc)
                  setOpen(true)
                }}
              />
            </motion.div>
          ) : (
            <div className=" z-10 text-[8vw]">Projects</div>
          )}
        </div>

        <div ref={containerRef} className="absolute inset-0 z-10">
          {projectsFirstColumn.map((project, index) => (
            <div key={project.id} className="">
              <CardAnimated
                name={project.name}
                project={project}
                containerRef={containerRef}
                content={project.content}
                imageSrc={project.imageSrc}
                imageAlt={project.imageAlt}
                tags={project.tags}
                link={project.link}
                githubLink={project.githubLink}
                colour={project.colour}
                video={project.video}
                onClick={(event) => {
                  event.stopPropagation()
                  setVideoUrl(project.video)
                  setProjectName(project.name)
                  setImage(project.imageSrc)
                  setOpen(true)
                }}
                className={""}
                top={top[index % top.length]}
                left={left[index % left.length]}
                rotate={rotate[index % rotate.length]}
                setSelectedProject={setSelectedProject}
                selectedProject={selectedProject}
              />
            </div>
          ))}
        </div>
      </div>
      <VideoModal
        projectName={projectName}
        open={open}
        setOpen={setOpen}
        video={videoUrl}
        image={image}
      />
    </div>
  )
}
