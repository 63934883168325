import Chip from "./Chip"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faGithubAlt } from "@fortawesome/free-brands-svg-icons"
import {
  faArrowUpRightFromSquare,
  faExpand,
} from "@fortawesome/free-solid-svg-icons"
import "./styles/card.css"
import { motion } from "framer-motion"
import React, { useState } from "react"
import { twMerge } from "tailwind-merge"

const Card = ({
  name,
  content,
  imageSrc,
  imageAlt,
  tags,
  link,
  githubLink,
  colour,
  video,
  onClick,
}) => {
  const chipColours = [
    "bg-[#D9FFFC] text-[#2CB67D]",
    "bg-[#e8e4ff] text-[#846CFD]",
    "bg-[#D9F0FF] text-[#2B6CB0]",
    "bg-[#FED7E2] text-[#C02652]",
    "bg-[#FFF4B3] text-[#F59E0B]",
    "bg-[#FFD6A5] text-[#D97706]",
  ]
  return (
    <div className="overflow-hidden rounded-lg bg-white border">
      <div className="px-4 py-5 sm:px-6 bg-white text-left">
        <div className={`flex place-content-between items-center $`}>
          <div>
            <h2 className="text-lg font-semibold text-gray-950">{name}</h2>
          </div>
          <div className="flex gap-3">
            {link && (
              <a href={link} target="_blank" rel="noopener noreferrer">
                <FontAwesomeIcon
                  className="h-5 w-5 text-black bg-white border border-slate-400 p-2 inline-block rounded-md transition ease-in hover:bg-black hover:text-white hover:border-black"
                  icon={faArrowUpRightFromSquare}
                />
              </a>
            )}
            {githubLink && (
              <a href={githubLink} target="_blank" rel="noopener noreferrer">
                <FontAwesomeIcon
                  className="h-5 w-5 text-black bg-white border border-slate-400 p-2 inline-block rounded-md transition ease-in hover:bg-black hover:text-white hover:border-black"
                  icon={faGithubAlt}
                />
              </a>
            )}
          </div>
        </div>
        <div className="mt-4 text-sm text-slate-900">{content}</div>
      </div>
      <div>
        <img
          src={imageSrc}
          alt={imageAlt}
          className="object-cover cursor-pointer"
          onClick={onClick}
        />
      </div>
      <div className="px-4 py-4 sm:px-6">
        {tags.map((tag, index) => (
          <Chip
            text={tag}
            key={tag}
            colour={chipColours[index % chipColours.length]}
          />
        ))}
      </div>
    </div>
  )
}

const CardAnimated = ({
  name,
  imageSrc,
  imageAlt,
  tags,
  link,
  githubLink,
  colour,
  video,
  onClick,
  containerRef,
  className,
  top,
  left,
  rotate,
  setSelectedProject,
  selectedProject,
  project,
}) => {
  const chipColours = [
    "bg-[#D9FFFC] text-[#2CB67D]",
    "bg-[#e8e4ff] text-[#846CFD]",
    "bg-[#D9F0FF] text-[#2B6CB0]",
    "bg-[#FED7E2] text-[#C02652]",
    "bg-[#FFF4B3] text-[#F59E0B]",
    "bg-[#FFD6A5] text-[#D97706]",
  ]

  const [isExpanded, setIsExpanded] = useState(false)
  const [isDragging, setIsDragging] = useState(false)
  const [zIndex, setZIndex] = useState(0)
  const [isActive, setIsActive] = useState(false)

  const updateZIndex = () => {
    const els = document.querySelectorAll(".drag-elements")
    let maxZIndex = -Infinity
    els.forEach((el) => {
      let zIndex = parseInt(
        window.getComputedStyle(el).getPropertyValue("z-Index")
      )
      if (!isNaN(zIndex) && zIndex > maxZIndex) {
        maxZIndex = zIndex
      }
    })
    setZIndex(maxZIndex + 1)
  }

  return (
    <>
      <motion.div
        drag
        dragConstraints={containerRef}
        onMouseDown={updateZIndex}
        onDragStart={() => setIsDragging(true)}
        onDragEnd={() => setIsDragging(false)}
        animate={{ maxWidth: isExpanded ? "40rem" : "22rem" }}
        className={twMerge(
          "drag-elements overflow-hidden rounded-lg bg-white border cursor-move absolute shadow-md min-w-[22rem]",
          className
        )}
        style={{ top, left, rotate, zIndex }}
        tabIndex={0}
        whileDrag={{ scale: 1 }}
      >
        <div className="px-4 py-5 sm:px-6 bg-white text-left">
          <div className={`flex place-content-between items-center $`}>
            <div>
              <h2 className="text-lg font-semibold text-gray-950">{name}</h2>
            </div>
            <div className="flex gap-3">
              {link && (
                <a href={link} target="_blank" rel="noopener noreferrer">
                  <FontAwesomeIcon
                    className="h-5 w-5 text-black bg-white border border-slate-400 p-2 inline-block rounded-md transition ease-in hover:bg-black hover:text-white hover:border-black"
                    icon={faArrowUpRightFromSquare}
                  />
                </a>
              )}
              {githubLink && (
                <a href={githubLink} target="_blank" rel="noopener noreferrer">
                  <FontAwesomeIcon
                    className="h-5 w-5 text-black bg-white border border-slate-400 p-2 inline-block rounded-md transition ease-in hover:bg-black hover:text-white hover:border-black"
                    icon={faGithubAlt}
                  />
                </a>
              )}
              <FontAwesomeIcon
                className="h-5 w-5 text-black bg-white border border-slate-400 p-2 inline-block rounded-md transition ease-in hover:bg-black hover:text-white hover:border-black"
                icon={faExpand}
                onClick={(e) => {
                  if (!isDragging) {
                    setIsExpanded(!isExpanded)
                  }
                }}
              />
            </div>
          </div>
          <button
            className={twMerge(
              "my-2 font-semibold text-xs py-1 px-2 rounded transition ease-in",
              selectedProject?.name === project.name
                ? "bg-black text-white"
                : "bg-slate-200 text-slate-700 hover:bg-black hover:text-white"
            )}
            onClick={(event) => {
              event.stopPropagation()
              setSelectedProject(project)
            }}
          >
            View Project Details
          </button>
        </div>
        <div>
          <img
            src={imageSrc}
            alt={imageAlt}
            className="object-cover cursor-pointer"
            onClick={onClick}
          />
        </div>

        <div className="px-4 py-4 sm:px-6">
          {tags.map((tag, index) => (
            <Chip
              text={tag}
              key={tag}
              colour={chipColours[index % chipColours.length]}
            />
          ))}
        </div>
      </motion.div>
    </>
  )
}

const ViewCard = ({
  name,
  content,
  imageSrc,
  imageAlt,
  tags,
  link,
  githubLink,
  colour,
  video,
  onClick,
}) => {
  const chipColours = [
    "bg-[#D9FFFC] text-[#2CB67D]",
    "bg-[#e8e4ff] text-[#846CFD]",
    "bg-[#D9F0FF] text-[#2B6CB0]",
    "bg-[#FED7E2] text-[#C02652]",
    "bg-[#FFF4B3] text-[#F59E0B]",
    "bg-[#FFD6A5] text-[#D97706]",
  ]
  return (
    <div>
      <motion.div className="max-w-2xl" transition={{ duration: 0.5 }}>
        <div className="px-4 py-5 sm:px-6 bg-white text-left">
          <div className="flex place-content-between items-center">
            <div>
              <h2 className="text-lg font-semibold text-gray-950">{name}</h2>
            </div>
            {/* <div className="flex gap-3">
              {link && (
                <a href={link} target="_blank" rel="noopener noreferrer">
                  <FontAwesomeIcon
                    className="h-5 w-5 text-black bg-white border border-slate-400 p-2 inline-block rounded-md transition ease-in hover:bg-black hover:text-white hover:border-black"
                    icon={faArrowUpRightFromSquare}
                  />
                </a>
              )}
              {githubLink && (
                <a href={githubLink} target="_blank" rel="noopener noreferrer">
                  <FontAwesomeIcon
                    className="h-5 w-5 text-black bg-white border border-slate-400 p-2 inline-block rounded-md transition ease-in hover:bg-black hover:text-white hover:border-black"
                    icon={faGithubAlt}
                  />
                </a>
              )}
            </div> */}
          </div>

          <div className="mt-4 text-sm text-slate-900">{content}</div>
        </div>

        <div>
          {video ? (
            <video src={video} autoPlay></video>
          ) : (
            <img src={imageSrc} />
          )}
        </div>

        <div className="py-4 scroller ml-14">
          <div className="scroll-content">
            {tags.map((tag, index) => (
              <Chip
                text={tag}
                key={tag + "-1"}
                colour={chipColours[index % chipColours.length]}
              />
            ))}
            {tags.map((tag, index) => (
              <Chip
                text={tag}
                key={tag + "-1"}
                colour={chipColours[index % chipColours.length]}
              />
            ))}
          </div>
        </div>
      </motion.div>
    </div>
  )
}

export { Card, CardAnimated, ViewCard }
