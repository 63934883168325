import { useState, useEffect } from "react"
import ProjectsLarge from "./ProjectsInteractive"
import ProjectsSimple from "./ProjectsSimple"

export default function Projects() {
  const [isLargeScreen, setIsLargeScreen] = useState(window.innerWidth > 1400)
  const [simplifiedView, setSimplifiedView] = useState(false)

  useEffect(() => {
    const handleResize = () => {
      setIsLargeScreen(window.innerWidth > 1400)
    }

    window.addEventListener("resize", handleResize)

    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [])

  return (
    <div className="bg-slate-100">
      {isLargeScreen && (
        <button
          className="mt-4 font-semibold text-xs py-1 px-2 rounded transition ease-in bg-slate-200 text-slate-700 hover:bg-black hover:text-white sticky top-4 z-50"
          onClick={() => setSimplifiedView(!simplifiedView)}
        >
          {simplifiedView ? "Interactive View" : "Minimalistic View"}
        </button>
      )}
      {isLargeScreen && !simplifiedView ? (
        <ProjectsLarge />
      ) : (
        <ProjectsSimple />
      )}
    </div>
  )
}
