export default function About() {
  return (
    <div className="bg-white">
      <div className="mx-auto max-w-3xl px-4 py-4 sm:px-6 lg:px-8">
        <div className="sm:flex sm:items-baseline sm:justify-between"></div>

        <div className="mt-2">
          <div className="text-left">
            <h2 className="text-7xl pb-8 sm:py-10 lg:py-10 font-bold tracking-tight text-gray-900  text-center sm:text-left lg:text-left">
              About me
            </h2>
            <div className="font-roboto font-light">
              <p className="pb-4 leading-8">
                Hello! My name is Sara! I'm a{" "}
                <span className="font-normal">Full Stack Web Developer</span>{" "}
                and <span className="font-normal">UI/UX designer</span>. I began
                my career at <span className="font-normal">Elastic Path</span>{" "}
                in 2022 as a Front-End Software Engineer. During my time there,
                I had the opportunity to work with various teams. I also work part-time as a UI/UX designer, and
                in my free time, I enjoy building{" "}
                <span className="font-normal">full-stack web applications</span>{" "}
                and learning how to create{" "}
                <span className="font-normal">games</span>.
              </p>
              <p className="pb-4 leading-8">
                I have always been inspired by beautiful{" "}
                <span className="font-normal">design</span> and the intersection
                between <span className="font-normal">technology</span> and{" "}
                <span className="font-normal">creativity</span>.
              </p>
              <p className="leading-6 font-sometype font-semibold text-sm">
                OUTSIDE OF WORK
              </p>
              <p className="pb-4 leading-8">
                I enjoy all things creative, whether it's reading, painting,
                drawing, or listening to podcasts. My current favorites are{" "}
                <span className="font-normal">"Diary of a CEO"</span> and{" "}
                <span className="font-normal">"Star Talk."</span> In my spare
                time, I love experimenting with electronics. I've taken an
                introductory robotics course where I built a line-following
                robot and an Otto robot. I'm currently working on a project for
                a habit calendar that connects to a web app.
              </p>
              <img
                src="images/electronics.jpg"
                alt="Working on my otto robot"
                className="mb-4"
              />
              <p className="pb-4 leading-8">
                The few times during a day where I am away from my desk, I enjoy
                going to the gym, boxing, shopping, and spending time with
                family and friends. I also love traveling, and my favorite
                destinations so far have been{" "}
                <span className="font-normal">Tokyo</span> and{" "}
                <span className="font-normal">Paris</span>.
              </p>
              <p className="leading-8 pb-4">
                Oh, and I also <span className="font-normal">love</span> cats.
              </p>
            </div>
          </div>
          <div>
            <img src="images/shadow.png" alt="My cat shadow" className="mb-4" />
          </div>
        </div>
      </div>
    </div>
  )
}
