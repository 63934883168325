import { Routes, Route } from "react-router-dom"
import "./App.css"
import Nav from "./components/Nav"
import About from "./components/About"
import Projects from "./components/Projects"

function App() {
  return (
    <div className="App font-sometype">
      <Nav />
      <Routes>
        <Route path="/" element={<Projects />} />
        <Route path="/about" element={<About />} />
      </Routes>
    </div>
  )
}

export default App
